body {
  background: url("../images/background.jpg") center top repeat;
}
.container {
  background-color: white;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
  color: #0d3864;
}

.carousel-control.left,
.carousel-control.right {
  background-image: none;
  color: black;
}
.close {
  opacity: 1;
}
.btn-primary:not(.btn-link):not(.btn-flat) {
  background-color: #0d3864;
}
.btn-primary:hover:not(.btn-link):not(.btn-flat) {
  background-color: #df0005;
}
.form-control,
.form-group .form-control:focus,
.form-group-default .form-control:focus {
  background-image: linear-gradient(#0d3864, #0d3864),
    linear-gradient(#d2d2d2, #d2d2d2);
}
.dropShadow {
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
}

.panel-guide {
  height: 300px;
}
.btn-bottom {
  position: absolute;
  left: 35px;
  bottom: 20px;
}

.greyInfoBox {
  background-color: #e8e8e8;
  margin-bottom: 20px;
  padding: 20px;
}
.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.navbar {
  margin-left: -15px;
  margin-right: -15px;
}
.navbar-inner {
  padding-left: 0;
  padding-right: 0;
}

.callouts {
  height: 300px;
}
.callouts > a > img {
  position: absolute;
  bottom: 30px;
  width: 100%;
  padding-right: 30px;
}
.callouts > .panel-heading {
  height: 120px;
}

.topnav {
  color: #0d3864;
  margin-top: 10px;
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 1);
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 47%,
    rgba(237, 237, 237, 1) 100%
  );
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(255, 255, 255, 1)),
    color-stop(47%, rgba(246, 246, 246, 1)),
    color-stop(100%, rgba(237, 237, 237, 1))
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 47%,
    rgba(237, 237, 237, 1) 100%
  );
  background: -o-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 47%,
    rgba(237, 237, 237, 1) 100%
  );
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 47%,
    rgba(237, 237, 237, 1) 100%
  );
  background: radial-gradient(
    ellipse at center,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 47%,
    rgba(237, 237, 237, 1) 100%
  );
}
.navbar-nav > li {
  width: 100%;
}
.topnav > div > ul > li > a {
  color: #0d3864;
  font-size: 1.4em;
}
.specialbox {
  background-color: #e7b300;
  color: #0d3864;
}
.blueBG {
  background-color: #0d3864 !important;
}
.yellowBG {
  background-color: #e7b300 !important;
}
.redText {
  color: #df0005;
}
.yellowText {
  color: #e7b300;
}
.whiteText {
  color: #fff;
}
.nav-pills > li.active > a {
  background-color: #0d3864;
}
.right-chevrons {
  margin-left: 70%;
}
.delbox {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
}
.redBG {
  background-color: #df0005 !important;
}
.boxPad {
  padding-left: 15px;
}
.boxPad > h2,
.boxPad > h3 {
  padding-top: 5px;
}
.blueText {
  color: #0d3864;
}
.viewbasket {
  margin-left: -22px;
}
.boxPhone {
  font-size: 1.3em;
}

.header-captions {
  margin-left: -45px;
  list-style: none;
}
.header-captions > li > a {
  margin-top: -5px;
}
#newsletter {
  background-color: #fff;
}
#newsletter > input {
  padding-left: 5px;
}

#sidebar > div.blueBG.hidden-xs.dropShadow > h2 {
  padding-top: 5px;
}
.newsletterinput {
  width: 90%;
  margin: 0 auto;
}
#topsearch {
  margin-top: 5px;
  margin-bottom: 5px;
}
#topsearch > input {
  background-color: white;
  height: 41px;
  padding-left: 10px;
}
#social-bar {
  background: url("../images/background.jpg") center top repeat;
  height: 40px;
  color: #fff;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
#social-bar p {
  color: white;
  position: relative;
  top: 6px;
}

#social {
  position: relative;
  z-index: 2;
  float: right;
  list-style: none;
  margin: 0 0 0 20px;
  padding: 0;
  font-size: 0;
}
.social li {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  vertical-align: middle;
}
#social li:first-child {
  margin-right: 10px;
}
#socialsocial li:first-child {
  margin-right: 15px;
}
.social li a {
  display: block;
  width: 30px;
  height: 25px;
  line-height: 25px;
  padding: 0;
  color: #004882;
}
.social li:hover a {
  color: #df0005;
  text-decoration: underline;
}

.social .google {
  background: url("../images/icons/social/google.png") center center no-repeat;
}
.social .twitter {
  background: url("../images/icons/social/twitter.png") center center no-repeat;
}
.social .facebook {
  background: url("../images/icons/social/facebook.png") center center no-repeat;
}
.social .youtube {
  background: url("../images/icons/social/youtube.png") center center no-repeat;
}
.social li .google:before {
  content: "";
  background: url("../images/icons/social/google-hover.png");
}
.social li .twitter:before {
  content: "";
  background: url("../images/icons/social/twitter-hover.png");
}
.social li .facebook:before {
  content: "";
  background: url("../images/icons/social/facebook-hover.png");
}
.social li .youtube:before {
  content: "";
  background: url("../images/icons/social/youtube-hover.png");
}

.social li:hover .google {
  background: url("../images/icons/social/google-hover.png") center center
    no-repeat;
}
.social li:hover .twitter {
  background: url("../images/icons/social/twitter-hover.png") center center
    no-repeat;
}
.social li:hover .facebook {
  background: url("../images/icons/social/facebook-hover.png") center center
    no-repeat;
}
.social li:hover .youtube {
  background: url("../images/icons/social/youtube-hover.png") center center
    no-repeat;
}

.sidebar-container {
  padding: 0px;
  margin: 0px;
}
.navbar-header {
  background-color: #0d3864;
}
.sidebar {
  margin: 0px;
  padding: 0px;
}

.navbar .navbar-nav > li > a {
  padding-top: 5px;
  padding-bottom: 5px;
}
.navbar.navbar,
.navbar-default.navbar {
  background-color: white;
}
.navbar-nav {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: block;
  font:
    bold 16px Helvetica,
    Verdana,
    Arial,
    sans-serif;
  line-height: 100%;
  width: 100%;
}

.navbar-nav li {
  margin: 0;
  padding: 0;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding-top: 0px;
}

.navbar-nav li a {
  display: block;
  text-decoration: none;
  color: #fff;
  background: #0d3864;
}

.navbar-nav li a:hover,
.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover,
.nav-pills > li > a:hover {
  background: #df0005 0 9px;
  color: #fff;
}

.navbar-nav li a.current,
.navbar-nav li a.current:hover {
  background: #0d3864 0 9px;
}

.navbar .navbar-nav > li > a:hover,
.navbar .navbar-nav > li > a:focus {
  background-color: #df0005;
}

.training-video {
  width: 94%;
}

.carousel-caption {
  right: 0px;
  left: 60%;
  float: right;
  height: 100%;
  background: rgba(100, 100, 100, 0.8);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 50px;
  font-size: 1.5em;
  bottom: 0px;
}

.top-badge {
  top: -10px;
  padding: 10px 17px;
  font-size: 16px;
  font-weight: 700;
  line-height: 2;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  background-color: #c9141c;
  border-radius: 0 0 20px 20px;
  z-index: 99999;
  position: absolute;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
}

.top-product-box > img {
  width: 100%;
}

.badge-blurb {
  min-width: 10px;
  padding: 10px 17px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  background-color: #c9141c;
  border-radius: 0 0 20px 20px;
  z-index: 99999;
  position: absolute;
  right: 12%;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
}
/*
Search box
*/

.stylish-input-group .input-group-addon {
  background: white !important;
}
.stylish-input-group .form-control {
  border-right: 0;
  box-shadow: 0 0 0;
  border-color: #ccc;
}
.stylish-input-group button {
  border: 0;
  background: transparent;
}

/*
Search box
*/

/*
Countdown timer
*/

#timer {
  /* text-align: center; */
  border: 2px solid #004853;
  display: inline;
  padding: 2px;
  color: #004853;
  font-family: Verdana, sans-serif, Arial;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
}

/*
Countdown timer
*/

.carousel-control {
  width: 4%;
}

div.c-wrapper {
  width: 90%; /* for example */
  margin: auto;
}

.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  width: 100%; /* use this, or not */
  margin: auto;
}

.product-category-name {
  height: 40px;
  padding-top: 10px;
}

@media (max-width: 480px) {
  h4 {
    font-size: 0.9em;
  }
}

.productcatbox {
  height: 255px;
  padding: 10px !important;
}

.productcatbox > .thumbnail > a > img {
  height: 170px;
}

.tick-list {
  list-style: none;
}

.tick-list > li {
  position: relative;
}

.tick-list > li:before {
  /*
    The desired width gets defined in two places: The element width, and background size.
    The height only gets defined once, in background size.
    */
  position: absolute;
  display: block;
  content: "\2022"; /* bullet point, for screen readers */
  text-indent: -999999px; /* move the bullet point out of sight */
  left: -3em;
  top: 0.3em;
  width: 2em; /* desired width of the image */
  height: 2em; /* unrelated to image height; this is so it gets snipped */
  background-repeat: no-repeat;
  background-image: url("/images/yellow-box-blue-tick.png");
  background-size: 2em 2em;
}

.tick-list-white > li:before {
  /*
    The desired width gets defined in two places: The element width, and background size.
    The height only gets defined once, in background size.
    */
  position: absolute;
  display: block;
  content: "\2022"; /* bullet point, for screen readers */
  text-indent: -999999px; /* move the bullet point out of sight */
  left: -3em;
  top: 0.3em;
  width: 2em; /* desired width of the image */
  height: 2em; /* unrelated to image height; this is so it gets snipped */
  background-repeat: no-repeat;
  background-image: url("/images/white-tick.png");
  background-size: 2em 2em;
}

.exclamation-list-white > li:before {
  /*
  The desired width gets defined in two places: The element width, and background size.
  The height only gets defined once, in background size.
  */
  position: absolute;
  display: block;
  content: "\2022"; /* bullet point, for screen readers */
  text-indent: -999999px; /* move the bullet point out of sight */
  left: -3em;
  top: 0.3em;
  width: 2em; /* desired width of the image */
  height: 2em; /* unrelated to image height; this is so it gets snipped */
  background-repeat: no-repeat;
  background-image: url("/images/white-exclamation.png");
  background-size: 2em 2em;
}
.whiteText {
  color: white;
}

.greyBG {
  background-color: #8a98a0 !important;
}

/* Catalyst chart stuff */
.tg {
  border-collapse: collapse;
  border-spacing: 0;
  margin: auto;
  width: 100%;
}
.tg td {
  font-size: 14px;
  padding: 10px 5px;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  word-break: normal;
  background-color: white;
  color: black;
}
.tg th {
  font-size: 14px;
  font-weight: normal;
  padding: 10px 5px;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  word-break: normal;
  background-color: white;
  color: black;
}
.tg .tg-s6z2 {
  text-align: center;
}
.tg .tg-t8po {
  font-weight: bold;
  background-color: #0f427f;
  color: #fdb813;
  text-align: center;
}
.tg .tg-40tt {
  font-weight: bold;
  background-color: #0f427f;
  color: #fdb813;
  text-align: center;
}
.tg .tg-ecyk {
  font-weight: bold;
  background-color: #fdb813;
  color: #0f427f;
  text-align: center;
}
/* End of Catalyst chart stuff */

.team {
  height: 200px !important;
}

.usps-list {
  padding: 0;
  margin: 22px 0 44px 0;
  list-style: none;
}

.usps-list li {
  padding-right: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  counter-increment: list-counter;
}

.usps-list li img {
  float: left;
  margin-right: 20px;
  width: 20%;
}

.select {
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  color: #616263;
  overflow: hidden;
  height: 40px;
  width: 240px;
  position: relative;
  display: block;
}

.optionSelect {
  height: 40px;
  padding: 5px;
  background-color: #ececec;
  border: 0;
  outline: none;
  font-size: 16px;
  width: 240px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.select:after {
  /*content:"▼";    */
  /*padding: 12px 8px;i */
  padding: 2px 6px;
  font-family: "Glyphicons Halflings";
  font-size: 1.9em;
  content: "\e259";
  position: absolute;
  right: 10px;
  top: 0;
  z-index: 1;
  text-align: center;
  width: 10%;
  height: 100%;
  pointer-events: none;
  box-sizing: border-box;
}

#fakePriceWrap {
  text-decoration: line-through;
  color: red;
}

.googlemap {
  border: 0;
  height: 600px;
  width: 100%;
}
.productcatbox > a > img.img-responsive {
  /*max-width: 243px;*/
  max-height: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.top-nav-dropdown {
  font-size: 1.4em;
  background-color: transparent;
  border: 0;
  padding-top: 10px;
}

.form-group-field {
  display: none;
}

.carousel-caption-mobile {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px !important;
  font-size: 2.5vw !important;
  bottom: 0px;
}
